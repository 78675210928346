// import "~bootstrap-icons";
import './sass/index.scss';
// import Glide from '@glidejs/glide'

import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js'

let pplPoints = document.getElementsByClassName('accordion');
console.log(pplPoints)





/*creates an array of all buttons with an accordion class*/
const acc = document.querySelectorAll(".accordion");
/*Opens first modal by default*/
let panelTarget = document.querySelector(
    '.panel[data-panel="' + 1 + '"]'
);
panelTarget.style.display = "flex";
let btn;

/*Loops through array of accordion buttons*/
for (let i = 0; i < acc.length; i++) {

    /* current index accordion button data attr number*/
    btn = acc[i].getAttribute("data-button");

    /*current index modal data attr number*/
    panelTarget = document.querySelector(
        '.panel[data-panel="' + btn + '"]'
    );

    /*adds event listener to each accordion button*/
    acc[i].addEventListener("click", function (e) {
        let btnTarget = e.currentTarget.getAttribute("data-button");
        expand(btnTarget);
    });
}

/*based off event target, this function will close or open modal */
function expand(num) {
    btn = document.querySelector(
        '.accordion[data-button="' + num + '"]'
    );
    panelTarget = document.querySelector(
        '.panel[data-panel="' + num + '"]'
    );
    btn.classList.add("selected");
    panelTarget.style.display = "flex";
    // panelTarget.classList.add("selected");

    /*loops through accordion array and closes any modals that may be open other than the selected one*/
    for (let j = 0; j < acc.length; j++) {
        if (j !== num - 1) {
            let x = j + 1;
            btn = document.querySelector(
                '.accordion[data-button="' + x + '"]'
            );
            panelTarget = document.querySelector(
                '.panel[data-panel="' + x + '"]'
            );
            panelTarget.style.display = "none";
            btn.classList.remove("selected");
        }
    }
}
